import { merge } from 'icepick';
import { fg } from '@atlassian/jira-feature-gating';
import {
	defaultOptions,
	getDefaultOptions,
} from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import FetchError from './errors.tsx';
import { getReroutableURL } from './get-reroutable-url.tsx';
import { applyObservabilityHeaders } from './observability-headers.tsx';
import { getTraceId } from './trace-id.tsx';

/**
 * Fetch from the given URL, returning the json response.
 * <p>
 * If there are any HTTP errors the promise is rejected with a {@link FetchError}.
 *
 * @param {string} url - to fetch the JSON from
 * @param {object} options - for the fetch call
 * @returns promise containing the JSON
 * @throws {@link FetchError} if there was an HTTP Error. This will reject the promise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default <TResponse = any,>(url: string, options: RequestInit = {}): Promise<TResponse> => {
	let res: Promise<Response>;

	if (fg('add_observability_headers_to_fetch_default_options')) {
		const reroutableURL = getReroutableURL(url);
		const newDefaultOptions = getDefaultOptions(reroutableURL);
		const mergedOptions = {
			...newDefaultOptions,
			...options,
			headers: {
				...newDefaultOptions.headers,
				...options.headers,
			},
		};

		res = fetch(reroutableURL, mergedOptions);
	} else {
		res = fetch(
			getReroutableURL(url),
			applyObservabilityHeaders(url, merge(defaultOptions, options)),
		);
	}

	return res.then((response) => {
		if (!response.ok) {
			const traceId = getTraceId(response);
			if (traceId !== undefined && traceId.length > 0) {
				throw new FetchError(
					response.status,
					`Fetch call failed with status code: ${response.status}`,
					traceId,
					response,
				);
			} else {
				throw new FetchError(response.status, undefined, undefined, response);
			}
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return response.json() as Promise<TResponse>;
	});
};
